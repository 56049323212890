import React, { Component } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap"

import { Amplify } from "aws-amplify"
import { withAuthenticator } from "@aws-amplify/ui-react"

import API from "./API"
import ReactFileReader from "react-file-reader"
import XLSX from "xlsx"
import moment from "moment"

import logo from "./img/ecogy.png"
import logoRound from "./img/logo.png"

import "./App.scss"

const pLimit = require("p-limit")

Amplify.configure({
  Auth: {
    region: "us-east-1",
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  },
})

class App extends Component {
  render() {
    return (
      <div className="app">
        <header className="app-header">
          <img src={logo} className="logo" alt="logo" />
        </header>
        <Content />
      </div>
    )
  }
}

class Content extends Component {
  constructor(props) {
    super(props)

    this.state = { addressesFile: "", radius: 0 }

    this.handleAddressFile = this.handleAddressFile.bind(this)
    this.storeAddresses = this.storeAddresses.bind(this)
    this.findAddressDetails = this.findAddressDetails.bind(this)
    this.downloadAddresses = this.downloadAddresses.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal })
  }

  storeAddresses(e) {
    var data = new Uint8Array(e.target.result)
    var workbook = XLSX.read(data, { type: "array" })

    /* convert from workbook to array of arrays */
    var first_worksheet = workbook.Sheets[workbook.SheetNames[0]]
    var addressesJson = XLSX.utils.sheet_to_json(first_worksheet, { header: 1, blankrows: false })

    this.setState({ addressesJson: addressesJson, loading: false })
  }

  handleAddressFile(files) {
    this.setState({ loading: "Uploading Addresses ..." })

    var f = files[0]
    var reader = new FileReader()
    reader.onload = this.storeAddresses
    reader.readAsArrayBuffer(f)
  }

  downloadAddresses() {
    /* convert from array of arrays to workbook */
    var worksheet = XLSX.utils.aoa_to_sheet(this.state.addressesJson)
    var workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Addresses")
    XLSX.writeFile(workbook, `addresses-${moment().format("YYYY-MM-DDTHH-mm-ss")}.xlsx`)
  }

  async findAddressDetails() {
    this.toggleModal()
    this.setState({ loading: `Retrieving Business Details for ${(this.state.addressesJson.length - 1).toLocaleString()} Addresses ...` })

    let columnHeaders = this.getColumnHeaders()
    const BATCH_SIZE = 50
    const limit = pLimit(1) // Only run X promises at a time
    let promises = []

    for (let i = 1; i < this.state.addressesJson.length; i += BATCH_SIZE) {
      promises.push(limit(() => this.poolAddresses(i, BATCH_SIZE, columnHeaders.indexes)))
    }

    await Promise.all(promises)
      .then((results) => {
        let businessDetails = [columnHeaders.headers].concat(...results)
        this.setState({ addressesJson: businessDetails, loading: false })
      })
      .catch((err) => {
        this.setState({ error: err, loading: false })
      })
  }

  poolAddresses(startIndex, batchSize, indexes) {
    let endIndex = startIndex + batchSize - 1
    this.setState({
      loading: `Retrieving Business Details for ${startIndex.toLocaleString()}-${
        endIndex > this.state.addressesJson.length ? (this.state.addressesJson.length - 1).toLocaleString() : endIndex.toLocaleString()
      } of ${(this.state.addressesJson.length - 1).toLocaleString()} Addresses ...`,
    })
    return API.post("/tools/address-business-details" + (this.state.radius ? "?radius=" + this.state.radius : ""), {
      indexes: indexes,
      addresses: this.state.addressesJson.slice(startIndex, startIndex + batchSize),
    }).then((response) => response.places)
  }

  getColumnHeaders() {
    let headers = this.state.addressesJson[0]
    let placeIdIdx = headers.findIndex((header) => header === "Place ID")
    let businessIdx = headers.findIndex((header) => header === "Business Name")
    let phoneIdx = headers.findIndex((header) => header === "Phone Number")
    let urlIdx = headers.findIndex((header) => header === "URL")

    if (placeIdIdx < 0) {
      placeIdIdx = headers.length
      headers[placeIdIdx] = "Place ID"
    }
    if (businessIdx < 0) {
      businessIdx = headers.length
      headers[businessIdx] = "Business Name"
    }
    if (phoneIdx < 0) {
      phoneIdx = headers.length
      headers[phoneIdx] = "Phone Number"
    }
    if (urlIdx < 0) {
      urlIdx = headers.length
      headers[urlIdx] = "URL"
    }

    return {
      headers: headers,
      indexes: {
        place: placeIdIdx,
        business: businessIdx,
        phone: phoneIdx,
        url: urlIdx,
      },
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading message={this.state.loading} />
    }
    if (this.state.error) {
      return (
        <div className="content">
          <Error error={this.state.error} />
          {this.renderActions()}
        </div>
      )
    }

    return (
      <div className="content">
        {this.state.addressesJson ? (
          <table>
            <tbody>
              {this.state.addressesJson.map((row, idx) => {
                return (
                  <tr key={idx}>
                    {row.map((column, colIdx) => {
                      if (idx === 0) {
                        return <th key={colIdx}>{column}</th>
                      }
                      return <td key={colIdx}>{column}</td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <div className="message">Upload a spreadsheet with address details to retrieve business details for.</div>
        )}

        {this.renderModal()}
        {this.renderActions()}
      </div>
    )
  }

  renderModal() {
    if (!this.state.addressesJson) {
      return null
    }
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    })

    return (
      <Modal isOpen={this.state.showModal} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>Confirm</ModalHeader>
        <ModalBody>
          <p>Google Places often fails to accurately match business details to places, you can optionally include nearby places by configuring a radius (0-200 meteres) to search for nearby places:</p>

          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Radius:</InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="radius"
              max="200"
              min="0"
              value={this.state.radius}
              onChange={(e) => {
                this.setState({ radius: e.target.value })
              }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>meters</InputGroupText>
            </InputGroupAddon>
          </InputGroup>

          <p>
            You are retrieving the business details for <b>{this.state.addressesJson.length - 1}</b> addresses
            {this.state.radius ? ` (and searching for additional nearby addresses within a ${this.state.radius} meter radius)` : null}, this will cost at least{" "}
            <b>{formatter.format(0.02 * this.state.addressesJson.length)}</b>, do you want to continue?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.findAddressDetails}>
            Retrieve
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              this.setState({ addressesJson: null, showModal: false })
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderActions() {
    return (
      <footer>
        {this.state.addressesJson ? (
          <div className="float-right">
            <Button color="info" onClick={this.toggleModal} className="download">
              Retrieve Business Details
            </Button>
            <Button color="primary" onClick={this.downloadAddresses}>
              Download
            </Button>
          </div>
        ) : null}

        <div className="float-left">
          <ReactFileReader handleFiles={this.handleAddressFile} fileTypes={[".xlsx", ".xls"]}>
            <Button>Upload Addresses</Button>
          </ReactFileReader>
        </div>
      </footer>
    )
  }
}

class Loading extends Component {
  render() {
    return (
      <div className="app-loading">
        <img src={logoRound} className="app-logo" alt="logo" />
        <div>{this.props.message ? this.props.message : "Loading ..."}</div>
      </div>
    )
  }
}

class Error extends Component {
  render() {
    return (
      <div className="error">
        <h2 className="error-title">Error</h2>
        <p className="error-message">{this.props.error.message}</p>
      </div>
    )
  }
}

export default withAuthenticator(App, { hideSignUp: true })

import { Auth } from "aws-amplify"
import fetch from "node-fetch"

var API = {
  get(url) {
    // If there isn't one (most cases) get the value WITHOUT caching it
    return Auth.currentSession()
      .then((session) => {
        return fetch(process.env.REACT_APP_ECOGY_API_URL + url, {
          headers: {
            Authorization: session.idToken.jwtToken,
          },
        })
          .then((response) => {
            return this.handleResponse(response)
          })
          .catch((err) => {
            return this.handleError(err)
          })
      })
      .catch((err) => {
        return this.handleError(err)
      })
  },

  post(url, body) {
    return Auth.currentSession()
      .then((session) => {
        return fetch(process.env.REACT_APP_ECOGY_API_URL + url, {
          method: "post",
          headers: {
            Authorization: session.idToken.jwtToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            return this.handleResponse(response)
          })
          .catch((err) => {
            return this.handleError(err)
          })
      })
      .catch((err) => {
        return this.handleError(err)
      })
  },

  postFormData(url, formData) {
    return Auth.currentSession()
      .then((session) => {
        return fetch(process.env.REACT_APP_ECOGY_API_URL + url, {
          headers: {
            Authorization: session.idToken.jwtToken,
          },
          method: "post",
          body: formData, // should this be form rather than body?
        })
          .then((response) => {
            return this.handleResponse(response)
          })
          .catch((err) => {
            return this.handleError(err)
          })
      })
      .catch((err) => {
        return this.handleError(err)
      })
  },

  put(url, body) {
    return Auth.currentSession()
      .then((session) => {
        return fetch(process.env.REACT_APP_ECOGY_API_URL + url, {
          headers: {
            Authorization: session.idToken.jwtToken,
            "Content-Type": "application/json",
          },
          method: "put",
          body: JSON.stringify(body, (key, value) => (typeof value === "undefined" ? null : value)), // note we use replacer to ensure the properties that have been explicitly set to undefined are included
        })
          .then((response) => {
            return this.handleResponse(response)
          })
          .catch((err) => {
            return this.handleError(err)
          })
      })
      .catch((err) => {
        return this.handleError(err)
      })
  },

  delete(url, body) {
    return Auth.currentSession()
      .then((session) => {
        return fetch(process.env.REACT_APP_ECOGY_API_URL + url, {
          headers: {
            Authorization: session.idToken.jwtToken,
            "Content-Type": "application/json",
          },
          method: "delete",
          body: JSON.stringify(body),
        })
          .then((response) => {
            return this.handleResponse(response)
          })
          .catch((err) => {
            return this.handleError(err)
          })
      })
      .catch((err) => {
        return this.handleError(err)
      })
  },

  handleResponse(response) {
    if (response.ok) {
      if (response.headers.get("Content-Type") === "application/json") {
        return response.json()
      } else {
        return response
      }
    } else if (response.status === 404) {
      return null
    } else {
      return response.json().then((error) => {
        if (error && (error === "No current user" || error.code === "NotAuthorizedException")) {
          window.location.reload() // Reload the page to show the login screen
        } else {
          return Promise.reject(error)
        }
      })
    }
  },

  handleError(err) {
    if (err && (err === "No current user" || err.code === "NotAuthorizedException")) {
      window.location.reload() // Reload the page to show the login screen
    } else {
      return Promise.reject(err)
    }
  },
}

export default API
